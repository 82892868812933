import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../Constants';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}`,
  credentials: 'include',
  prepareHeaders: (headers) => {
    const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    if (csrfToken) {
      headers.set('X-CSRFToken', csrfToken.split('=')[1]);
    }
    return headers;
  },
});

// A base query that includes logic to refresh tokens if needed
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Try the original request
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 Unauthorized, try to refresh the token
  if (result.error && result.error.status === 401) {
    // Try refreshing the token

    const refreshResult = await baseQuery({
      url: `${BASE_URL}/auth/token/refresh/`,
      method: 'POST',
    }, api, extraOptions);

    if (refreshResult.error) {
      // Handle refresh error (e.g., logout)
      api.dispatch({ type: `${BASE_URL}/auth/logout` });
    } else {
        // The refresh was successful; just retry the original request
        result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

// Create a base Api slice with no specific endpoints
export const ApiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),  // Empty initially, will be extended by injectEndpoints
});
