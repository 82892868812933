import { BASE_URL } from '../Constants';
import { ApiSlice } from "./ApiSlice";

const GetDataSlice=ApiSlice.injectEndpoints({
    endpoints:(builder)=>({
        getCourses:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/courses/`,
                method: 'GET'
            })
        }),
        getBlogs:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/posts/`,
                method: 'GET'
            })
        }),
        getBlog:builder.mutation({
            query:(id)=>({
                url: `${BASE_URL}/api/posts/${id}`,
                method: 'GET'
            })
        }),
        getLessons:builder.query({
            query: ()=>({
                url: `${BASE_URL}/api/lessons/`,
                method:'GET'
            })
        }),
        getModules:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/modules/`,
                method: 'GET'
            })
        }),
        getSubModules:builder.query({
            query:()=>({
                url: `${BASE_URL}/api/submodules/`,
                method: 'GET'
            })
        })
    })
})

export const {useGetCoursesQuery, useGetBlogsQuery, useGetBlogMutation, useGetLessonsQuery, useGetModulesQuery, useGetSubModulesQuery}= GetDataSlice;