import Newsletter from './Newsletter'
import  Splice  from './Splice'
import Footer from './Footer'

function Home() {
  return (
  <div > 
    <Splice />
    <Newsletter />
    <Footer />
  </div>)
}

export default Home