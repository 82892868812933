import React from 'react';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useGetCoursesQuery } from '../ThemeContextProvider/GetDataSlice';
import { Player } from '@lottiefiles/react-lottie-player'; 

const Courses = () => {
  const { data: courses, isLoading, isError } = useGetCoursesQuery();
  const { isDarkMode } = useSelector((Store) => Store.ThemeSlice);
  const {keyword} = useParams();
  const location=useLocation();
  const searchKeyword= keyword ? keyword.toLowerCase() : "";

  const filteredCourses= courses ? courses.filter((course)=> course.title.toLowerCase().includes(searchKeyword)) : [];
  
  const isSearchPage= location.pathname.includes("/dashboard/search")

  return (
    <div className={`max-w-[90%] lg:max-w-[80%] mx-auto ${isDarkMode ? 'text-white' : 'text-black'} pb-20`}>
      {isLoading ? (
        <div className='px-8 py-3 flex items-center justify-center h-screen'>
          <ClipLoader
            color={isDarkMode ? "#ffffff" : "#000000"}
            loading={isLoading}
            size={44}
          />
        </div>
      ) : isError ? (
        <div className="text-center pt-10">
          <p className="text-red-500">An error occurred while loading the courses. Please try again later.</p>
        </div>
      ) : (
        <div>
        {
          isSearchPage ? <h1 className=' text-lg sm:text-2xl font-semibold pb-4 border-b-[2.7px] border-gray-200 text-center sm:text-left'>{`Results for ${keyword}`}</h1>  
          : 
          <h1 className={`text-xl sm:text-3xl font-semibold pb-4 border-b-[3.2px] border-gray-300 text-center sm:text-left`}>Explore our courses</h1>
        } 
      
        <div className="flex justify-center pt-10">
          <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mx-4 sm:mx-8 lg:mx-16">
            {filteredCourses.length > 0 ? (
              filteredCourses.map((course) => (
                <Link to={`/dashboard/modules/${course.id}`}  key={course.id}
                className={`${
                  isDarkMode ? 'bg-zinc-900' : 'bg-gray-300  brightness-1'
                } rounded-lg p-4 shadow-md flex flex-col justify-between transform transition-transform duration-300 hover:scale-105 hover:cursor-pointer`}
              > 
               
                <div className="flex flex-col items-center">
                  
                    <Player
                      src={course.lottieicon}
                      className="w-32 sm:w-36 lg:w-40 h-auto"
                      loop
                      autoplay
                    />
                    <h3 className="text-base font-bold md:font-semibold mt-2 text-center">{course.title}</h3>
                    <p
                      className={`${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      } text-sm mt-1 text-center`}
                    >
                      {course.description}
                    </p>
                  
                </div>
                <button className="bg-sky-700 text-white rounded-md p-2 py-[6px] mt-4 w-full text-center">
                  Enroll Now
                </button>
               </Link>
            ))): (
              <div className="text-center text-lg col-span-full">
                <p className="text-gray-500">No courses found. Please go to <Link to="/dashboard/home" className='text-sky-600 font-semibold'>Home page</Link></p>
              </div>
            )}
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
