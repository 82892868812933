import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Navbar from "./Components/NavBar";
import Home from "./Components/Home";
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import Dashboard from "./Authentication/Dashboard";
import About from "./Components/About";
import Blogs from "./Components/Blogs";
import Blog from "./Components/Blog"; 
import Modules from "./Components/Modules";
import Lessons from "./Components/Lessons";
import Search from "./Components/Search";
import NotFound from "./Components/NotFound";
import ProtectedRoute from "./Authentication/ProtectedRoute"; // Import ProtectedRoute
import Courses from "./Components/Courses";
import { useSelector } from "react-redux";
import Logout from "./Authentication/Logout";

function AppLayout() {
  const { isDarkMode } = useSelector((store) => store.ThemeSlice);

  return (
    <div className={`${isDarkMode ? 'bg-black' : 'bg-gray-50 brightness-105'}`}>
      <Navbar />
      <Outlet />
    </div>
  );
}

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "courses",
            element: <Courses />,
          },
          {
            path: "modules/:id",
            element: (
              <ProtectedRoute>
                <Modules />
              </ProtectedRoute>
            ),
          },
          {
            path: "lessons/:id",
            element: (
              <ProtectedRoute>
                <Lessons />
              </ProtectedRoute>
            ),
          },
          {
            path: "search/:keyword",
            element: (
              <ProtectedRoute>
                <Search />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/blogs/:id",
        element: <Blog />,
      },
      {
        path: '*',
        element: <NotFound />
      }
    ],
  },
]);

export default function App() {
  return <RouterProvider router={appRoutes} />;
}