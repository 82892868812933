import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaBook, FaChartLine, FaProjectDiagram, FaMoneyBillWave, FaUsers, FaCog, FaBars, FaTimes } from 'react-icons/fa';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`lg:w-64 h-screen bg-gray-800 text-white fixed lg:relative z-50 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
            <div className="lg:hidden p-4">
                <button onClick={toggleSidebar} className="text-white focus:outline-none">
                    {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            </div>
            <nav className="mt-10">
                <NavLink to="/dashboard/home" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaHome className="mr-2" /> Home
                </NavLink>
                <NavLink to="/dashboard/courses" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaBook className="mr-2" /> My Courses
                </NavLink>
                <NavLink to="/dashboard/progress" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaChartLine className="mr-2" /> Progress
                </NavLink>
                <NavLink to="/dashboard/projects" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaProjectDiagram className="mr-2" /> My Projects
                </NavLink>
                <NavLink to="/dashboard/earnings" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaMoneyBillWave className="mr-2" /> Earnings
                </NavLink>
                <NavLink to="/dashboard/community" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaUsers className="mr-2" /> Community
                </NavLink>
                <NavLink to="/dashboard/settings" className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
                    <FaCog className="mr-2" /> Settings
                </NavLink>
            </nav>
        </div>
    );
};

export default Sidebar;